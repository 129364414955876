














import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class RightNavigation extends Vue {
  @Prop({ required: true })
  title!: string;
}
