







import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component
export default class RightNavLink extends Vue {
  @Prop({ required: true })
  to: string | Route;

  @Prop()
  icon: string;
}
